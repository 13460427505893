<template>
  <layout-toolbar class="shrimp-snap-toolbar">
    <p>{{ ftm__capitalize($t("shrimpsnap.sampling_details")) }}:</p>
    <el-col :span="3">
      <er-select
        :value="getSelectedSS"
        size="mini"
        value-key="_id"
        :loading="isLoading"
        @input="handleSelectedShrimpSnaps"
      >
        <el-option
          v-for="ss in getListOfSSDetails"
          :key="ss._id"
          :value="ss"
          :label="upm__getValue(ss)"
        ></el-option>
      </er-select>
    </el-col>
    <div class="filler"></div>
    <er-single-day-picker
      :value="getSelectedDate"
      v-if="getSelectedSSView === 'GRAPH'"
      value-format="yyyy-MM-dd"
      size="mini"
      :format="this.upm__getFormatDateString"
      :picker-options="{
        disabledDate: disableDateMethod
      }"
      @change-prev-day="handleValueChange"
      @change-next-day="handleValueChange"
      @change-day="handleValueChange"
    ></er-single-day-picker>
    <el-col :span="3">
      <er-select
        :value="getSelectedSSView"
        size="mini"
        @input="handleChangeSelectedView"
      >
        <el-option
          v-for="(label, value) in availableViews"
          :key="value"
          :value="value"
          :label="ftm__capitalize(label)"
        ></el-option>
      </er-select>
    </el-col>
  </layout-toolbar>
</template>

<script>
import userPreferenceMixin from "@/mixins/userPreferenceMixin";
import datesHandlerMixin from "@/mixins/datesHandlerMixin";
import errorHandlerMixin from "@/mixins/errorHandlerMixin";
import filtersMixin from "@/mixins/filtersMixin";
import hashSum from "hash-sum";
import { mapActions, mapGetters } from "vuex";
export default {
  mixins: [
    userPreferenceMixin,
    datesHandlerMixin,
    filtersMixin,
    errorHandlerMixin
  ],
  data() {
    return {
      listShrimpSnaps: [],
      selectedDate: undefined
    };
  },
  watch: {
    getDateRange: async function(newValue, oldValue) {
      if (hashSum(newValue) === hashSum(oldValue)) return;
      this.selectedDate = undefined;
      await this.initComponent();
    }
  },
  computed: {
    ...mapGetters("pondDetails/pondABWTab", {
      getDateValueFormat: "getDateValueFormat",
      getSelectedSS: "getSelectedSS",
      getDateRange: "getDateRange",
      getSelectedSSView: "getSelectedSSView",
      getListOfSSDetails: "getListOfSSDetails",
      getLoadingComponents: "getLoadingComponents"
    }),
    ...mapGetters("pondDetails", {
      getCurrentCulture: "getCurrentCulture",
      pondId: "getSelectedPondId",
      getSelectedPond: "getSelectedPondWithDeviceDetails"
    }),
    isLoading() {
      return this.getLoadingComponents.has("FETCH_SS_DETAILS");
    },
    availableViews() {
      return {
        LIST: this.$t("ui_selection_options.list"),
        IMAGE: this.$t("ui_selection_options.image"),
        GRAPH: this.$t("ui_selection_options.graph")
      };
    },
    getSelectedDate() {
      const choices = [
        this.selectedDate,
        this.getDateRange && this.getDateRange[1],
        this.dhm__dateUtilsLib.formatDate(new Date(), this.getDateValueFormat)
      ];
      return choices.find(x => x);
    },
    getDateObjArrOfCultureDateRange() {
      const start_date = this.dhm__dateUtilsLib.utcToZonedTime(
        this.getDateRange[0],
        this.getUserTimeZoneString
      );
      const end_date = this.dhm__dateUtilsLib.utcToZonedTime(
        this.getDateRange[1],
        this.getUserTimeZoneString
      );
      return [start_date, end_date];
    }
  },
  async mounted() {
    await this.initComponent();
  },
  methods: {
    ...mapActions("pondDetails/pondABWTab", {
      changeSelectedSS: "changeSelectedSS",
      changeSelectedSSView: "changeSelectedSSView",
      fetchShrimpSnapsByPondId: "fetchShrimpSnapsByPondId",
      changeSSABWGraphDate: "changeSSABWGraphDate"
    }),
    async initComponent() {
      try {
        await this.fetchShrimpSnapsByPondId();
        await this.handleValueChange(this.getSelectedDate);
      } catch (err) {
        this.ehm__errorMessages(err, true);
      }
    },
    handleSelectedShrimpSnaps(ss) {
      this.changeSelectedSS(ss);
    },
    handleChangeSelectedView(view) {
      this.changeSelectedSSView(view);
    },
    disableDateMethod: function(time) {
      const currDate = time;
      const paramObj = {
        dateRange: this.getDateObjArrOfCultureDateRange,
        date: currDate,
        timeZone: this.getUserTimeZoneString,
        actionsOnDate: [],
        actionsOnDateRangeItem: []
      };
      return !this.dhm__dateUtilsLib.queryInDateRange(paramObj);
    },
    handleValueChange: async function(datePicked) {
      this.selectedDate = datePicked;
      await this.changeSSABWGraphDate(datePicked);
    }
  }
};
</script>

<style lang="scss" scoped>
.shrimp-snap-toolbar {
  background-color: white;
  position: sticky;
  top: 0px;
  z-index: 10;
  padding: 5px 5px;
  &::v-deep .graph-date-picker {
    @include responsiveProperty(width, 130px, 140px, 150px);
  }
}
</style>
